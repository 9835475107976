<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <template v-slot:activator="{ on }">
      <app-add-button v-on="on" v-bind:module="'services'"></app-add-button>
    </template>
    <v-form ref="form" v-model="isvalid" lazy-validation>
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-btn icon dark @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md6>
                <v-autocomplete
                  v-model="payee_id"
                  :items="payefiles"
                  editable
                    :rules="defaultRules"
                  item-text="payee_desc"
                  item-value="id"
                  label="Payee"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm12 md6>
                <v-text-field
					v-model="service" 
                    :rules="defaultRules"
					label="Service">
				</v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md6>
                <v-select
                  v-model="vat_category"
                  :items="vat_categories"
                  :rules="defaultRules"
                  editable
                  item-text="name"
                  item-value="id"
                  label="VAT"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm12 md6>
                <v-select
                  v-model="service_tag"
                  :items="service_tags"
                  :rules="defaultRules"
                  editable
                  item-text="name"
                  item-value="id"
                  label="Service Tag"
                ></v-select>
              </v-flex>
              <v-flex md12>
                <v-simple-table dense v-if="details.length !== 0">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Account Code</th>
                        <th width="30%">Account Type</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(detail, detail_key) in details"
                        :key="detail.detail_key"
                      >
                        <td>{{ detail_key + 1 }}</td>
                        <td>
                          <v-autocomplete
							v-model="detail.account_code"
							:items="coaData"
                            :rules="defaultRules"
							item-text="code_name"
							item-value="acct_code"
						></v-autocomplete>
                        </td>
                        <td>
                          <v-select
                            v-model="detail.account_flag"
                            :items="account_flag"
                            editable
                            :rules="defaultRules"
                            item-text="name"
                            item-value="code"
                          ></v-select>
                        </td>
                        <td>
                          <v-btn icon>
                            <v-icon
                              small
                              color="red"
                              @click="deleteAccess(detail_key, detail)"
                              >delete</v-icon
                            >
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn right dark color="primary" @click="addAccount">
            <v-icon>mdi-plus</v-icon>
            <span>Add Account</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
          <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue,
	},
	watch: {
		editedItem: {
			handler(val) {
				if (val.length !== 0) {
					this.service_id = val[0].id;
					this.payee_id = val[0].payee_id;
					this.vat_category = val[0].vat_category;
					this.service_tag = val[0].service_tag;
					this.service = val[0].billing_service_name;
					if (val[0].billingentries.length > 0) {
						this.details = val[0].billingentries;
					}
				}
			},
		},
	},
	data: () => ({
		isvalid: true,
		service_id: '',
		service_tag: '',
		vat_category: '',
		service: '',
		payee_id: '',
		defaultRules: [
			v => !!v || 'Required field',
		],
		account_flag: [
			{ code: 1, name: 'AP/AR Account' },
			{ code: 2, name: 'Income Account' },
			{ code: 3, name: 'VAT Account' },
			{ code: 4, name: 'Withholding Tax' },
		],
		service_tags: [
			{ id: 1, name: 'AP' },
			{ id: 2, name: 'AR' },
		],
		vat_categories: [
			{ id: 1, name: 'VAT Inclusive' },
			{ id: 2, name: 'VAT Exclusive' },
			{ id: 3, name: 'VAT Exempt' },
		],
		details: [{ id: '', account_code: '', account_flag: '' }],
		deleted: [],
	}),

	computed: {
		...mapGetters({
			payefiles: 'payefile/payefiles',
			currUser: 'auth/currUser',
			editedItem: 'services/editedItem',
			formTitle: 'services/formTitle',
			valid: 'services/valid',
			dialog: 'services/dialog',
			editedIndex: 'services/editedIndex',
			coaData: 'coa/coaData',
		}),
	},
	mounted() {
		this.$store.dispatch('payefile/getPayefiles');
	},
	methods: {
		close() {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.payee_id = '';
				this.service_id = '';
				this.billingentries = [];
				this.deleted = [];
				this.details = [{ id: '', account_code: '', account_flag: '' }];
				this.$store.dispatch('services/setValid', false);
				this.$store.dispatch('services/setDialog', false);
				this.$store.dispatch('services/setEditedIndex', -1);
				this.isvalid = false;
			}, 2000);
		},

		async save() {
			this.$store.dispatch('services/setValid', this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			let updated = [];
			let billingentries = [];

			this.details.forEach((det) => {
				det.edit_by = this.currUser.id;
				if (det.id) {
					updated.push(det);
				} else {
					billingentries.push(det);
				}
			});

			let data = {
				id: this.service_id,
				billing_service_name: this.service,
				payee_id: this.payee_id,
				vat_category: this.vat_category,
				service_tag: this.service_tag,
				billingentries: billingentries,
				updated: updated,
				deleted: this.deleted,
				edit_by: this.currUser.id,
			};
			if (this.isvalid) {
				if (this.editedIndex > -1) {
					this.$store.dispatch('services/updateServices', data);
				} else {
					this.$store.dispatch('services/saveServices', data);
				}
				setTimeout(() => {
					this.close();
				}, 3000);
			} else {
				this.$store.commit(
					'app/SET_SNACK_BAR',
					{
						status: true,
						message: 'Please fill out all the required fields.',
						messagetype: 'error',
					},
					{ root: true }
				);
			}
		},
		addAccount() {
			const newAccount = { id: '', account_code: '', account_flag: '' };
			if (this.service_id) {
				newAccount.billing_service_id = this.service_id;
			}
			this.details.push(newAccount);
		},
		deleteAccess(key, detail) {
			this.details.filter((detail, index) => {
				if (index === key) {
					this.details.splice(key, 1);
				}
			});

			if (detail.id) {
				this.deleted.push({ id: detail.id });
			}
		},
	},
};
</script>