<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="services"
      class="elevation-1"
			dense
			:search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Services Maintenance</v-toolbar-title>
          <v-spacer></v-spacer>
			<v-text-field
			v-model="search"
			append-icon="search"
			label="Search"
			single-line
			hide-details
			></v-text-field> 
			<vue-excel-xlsx
				:data="services"
				:columns="columnHeaders"
				:file-name="module_name"
				>
				<v-btn icon alt class="mt-2" :color="services.length=== 0 ? 'gray' : 'primary'" :disabled="services.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
			</vue-excel-xlsx>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
		<v-icon
        color="grey"
        class="mr-2"
        small
		v-if="role_access < 3"
      >
        mdi-pencil-off
      </v-icon>
      <v-icon
        color="primary"
        v-else
        class="mr-2"
        small
        @click="editItem(item)"
      >
        edit
      </v-icon>
		<v-icon
        color="grey"
        class="mr-2"
        small
		v-if="role_access < 4"
      >
        mdi-delete-off
      </v-icon>
      <v-icon
        small
		v-else
        color="red"
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
    </v-data-table>
    <create-services v-if="role_access > 1" />
  </div>
</template>

<script>
import createServices from './createServices.vue';
import { mapGetters } from 'vuex';

export default {
	data(){
		return {
			module_name: 'Billing Services',
			role_access: 1,
			nextPageRegularTrx: '',
			search: ''
		};
	},
	components: {
		'create-services': createServices
	},
	computed: {
		...mapGetters( {
			payefiles: 'payefile/payefiles',
			services: 'services/services',
			headers: 'services/headers',
			columnHeaders: 'services/columnHeaders',
			editedItem: 'services/editedItem',
			defaultItem: 'services/defaultItem',
			dialog: 'services/dialog',
			editedIndex: 'services/editedIndex',
			isLoading: 'services/isLoading',
			valid: 'services/valid',
			coas: 'coa/coaSubs',
			coaData: 'coa/coaData',
			currUser: 'auth/currUser'
		}),
	},
	watch: {
		coas: {
			handler(){
				this.getDataCoas();
			}
		},
		services: {
			handler(val){
				console.log(val);
				val.forEach(det => {
					console.log(this.payefiles);
					let payee = this.payefiles.filter(pay => pay.id === det.payee_id);
					console.log(payee);
					if(payee.length > 0){
						det.payee_name = payee[0].payee_desc;
					}
				});
			}
		}
	},
	mounted () {
		this.$store.dispatch('coa/getCoaDropdown');
		this.$store.dispatch('payefile/getPayefiles');
		this.$store.dispatch('services/getServices');
		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('services/setEditedIndex', this.services.indexOf(item));
			// this.$store.dispatch('services/setEditedItems', Object.assign({}, item));
			this.$store.dispatch('services/getServicesDetail', item.id);
			this.$store.dispatch('services/setDialog', true);
			this.$store.dispatch('services/setValid', true);
			this.$store.dispatch('services/setformTitle', 'Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: `Remove ${item.services} from the list?`,
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('services/setEditedIndex', this.services.indexOf(item));
					this.$store.dispatch('services/deleteServices', item);
				}
			});
		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				// let coa = this.dataCoas.filter(e => e.acct_status === 1);
				// this.dataCoas = coa;
				let data = this.dataCoas.filter(e => parseInt(e.acct_status) === 1);
				this.$store.commit('coa/SET_COA_DATA', data);
			}
		},
	}
};
</script>
