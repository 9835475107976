<template>
	<div class="home">
		<table-services />
	</div>
</template>

<script>
import tableServices from '@/components/pages/maint/billingservices/tableServices.vue';

export default {
	components: {
		'table-services': tableServices,
	}
};
</script>

<style>

</style>
